<template>
  <UNotifications />
  <div class="logo pt-[6vw] mt-5 font-['Space_Grotesk'] w-full flex place-content-center">
    <div class="w-[400px] mx-10 mt-5">
      <div class="grid grid-rows-1 gap-1">
        <div class="pr-6">
          <div class="text-[26pt] my-8 leading-none font-['Space_Grotesk'] font-bold">
            <slot name="header" />
          </div>
        </div>
        <div class="">
          <slot name="content" />
        </div>
        <div class="mb-[20px] flex content-start items-baseline place-content-center">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.logo {
  background: url('~/assets/logo.svg') no-repeat center top;
}

body {
  height: 100px;
  background-image: url('~/assets/background-2xl-light.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>